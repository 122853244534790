import React from "react";
import "./App.css";
import {Routes, Route} from "react-router-dom";
import Error404 from "./pages/Error404";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

import About from "./pages/About";
import Bio from "./pages/Bio";
import Linux from "./pages/info/Linux";
import Locations from "./pages/info/Locations";
import Nano from "./pages/info/Nano";
import Bash from "./pages/info/Bash";
import Mysql from "./pages/info/Mysql";
import Git from "./pages/info/Git";
import Vim from "./pages/info/Vim";
import Postgres from "./pages/info/Postgres";
import Info from "./pages/Info";
import Projects from "./pages/Projects";

function App() {
    return (
        <div className="App container mx-auto">
            <header className="p-2 sm:p-0">
                <h1 className="text-5xl font-bold mt-5 mb-10"><a href="//nlud.uk/">Neil Ludlow <font
                    style={{color: 'grey', fontSize: 20}}>nlud.uk</font></a></h1>
            </header>
            <Nav/>
            <Routes>
                <Route path="/" element={<Bio/>}/>
                <Route path="about" element={<About/>}/>
                <Route path="projects" element={<Projects/>}/>
                <Route path="info/linux" element={<Linux/>}/>
                <Route path="info/nano" element={<Nano/>}/>
                <Route path="info/vim" element={<Vim/>}/>
                <Route path="info/locations" element={<Locations/>}/>
                <Route path="info/bash" element={<Bash/>}/>
                <Route path="info/mysql" element={<Mysql/>}/>
                <Route path="info/git" element={<Git/>}/>
                <Route path="info/postgres" element={<Postgres/>}/>
                <Route path="info" element={<Info/>}/>
                <Route path="*" element={<Error404/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
