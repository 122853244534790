import React from "react";


export default function Projects() {
    return (
        <div className="p-2 sm:p-0">
            <h2 className="main-head">Personal Projects</h2>

            <p>
                Here are a selection of some of my personal projects and open source code from over the years.
            </p>

            <h3 className="sub-head">Android/iOS app: MPG Multi Vehicle (2024)</h3>

            <p>
                iOS Link: <a
                href={'https://apps.apple.com/gb/app/mpg-multi-vehicle/id6504755327'} rel="noreferrer noopener"
                target="_blank">https://apps.apple.com/gb/app/mpg-multi-vehicle/id6504755327</a>
            </p>
            <p>
                Android Link: <a
                href={'https://play.google.com/store/apps/details?id=com.shortdarkapps.mpg_multi_vehicle'}
                rel="noreferrer noopener"
                target="_blank">https://play.google.com/store/apps/details?id=com.shortdarkapps.mpg_multi_vehicle</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>Flutter/Dart</font>, <font style={{fontWeight: 'bold'}}>Firebase
                Firestore</font>
            </p>

            <p>
                My second personal app.
                Re-worked the MPG tracking app concept, but this time created the app in a completely different way.
                Instead of each login being for one vehicle, a single login can track the MPG of multiple vehicles.
                As before, you enter the info every time you fill up your vehicle and can see graphs for each vehicle.
                The MPG of every vehicle can then be compared together in a combined MPG graph.
            </p>

            <h3 className="sub-head">Android/iOS app: UK MPG Calculator (2022)</h3>

            <p>
                iOS Link: <a
                href={'https://apps.apple.com/gb/app/uk-mpg-calculator/id1631513789'} rel="noreferrer noopener"
                target="_blank">https://apps.apple.com/gb/app/uk-mpg-calculator/id1631513789</a>
            </p>
            <p>
                Android Link: <a
                href={'https://play.google.com/store/apps/details?id=com.shortdark.tracksdemo20220611'}
                rel="noreferrer noopener"
                target="_blank">https://play.google.com/store/apps/details?id=com.shortdark.tracksdemo20220611</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>React Native</font>, <font
                style={{fontWeight: 'bold'}}>NodeJS</font>, <font style={{fontWeight: 'bold'}}>MongoDB</font>
            </p>

            <p>
                My first personal app on the app stores.
                I wanted a way to calculate the MPG of my car as it did not have a reading for MPG on the dash.
                Other MPG apps exist but I wanted to make one myself.
                I did a course in React Native and modified one of the projects to create the MPG app.
                Every time you put fuel into the car you need to fill up to the top and note the odometer reading.
                The app makes graphs of the vehicle's MPG and other things.
            </p>

            <h3 className="sub-head">NPM package: halflife-array (2024)</h3>

            <p>
                Link: <a
                href={'https://www.npmjs.com/package/halflife-array'} rel="noreferrer noopener"
                target="_blank">https://www.npmjs.com/package/halflife-array</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>JavaScript</font>
            </p>

            <p>
                I'd never made an NPM package so I thought I'd make one.
                This code is modified from something I was working on at the time.
                You specify the starting value, the half-life time, the interval you want between readings and the
                number to stop calculating at.
                The result is how the value changes over time.
            </p>


            <h3 className="sub-head">Composer package: shortdark/socket (2021)</h3>

            <p>
                Link: <a
                href={'https://packagist.org/packages/shortdark/socket'} rel="noreferrer noopener"
                target="_blank">https://packagist.org/packages/shortdark/socket</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>PHP</font>
            </p>

            <p>
                In 2021, I took some old PHP code that made an SVG line graph out of an array.
                There are instructions on how to use it, it needs an array to be able to show a graph, and there are
                many variables that can be changed depending on the use case.
                The exercise was mainly for myself to have some public open source code out there.
                It's not perfect but I think it works pretty well and I've used the package in several PHP projects.
            </p>

            <h3 className="sub-head">WordPress Plugin: Post Volume Stats (2016-19)</h3>

            <p>
                Link: <a
                href={'https://wordpress.org/plugins/post-volume-stats/'} rel="noreferrer noopener"
                target="_blank">https://wordpress.org/plugins/post-volume-stats/</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>PHP</font>, <font
                style={{fontWeight: 'bold'}}>jQuery</font>, <font
                style={{fontWeight: 'bold'}}>CSS</font>
            </p>

            <p>
                I've made a few WordPress plugins but this is the one I'm most proud of.
                The user installs the plugin and immediately get to see their own posting patterns.
                There is a main page that shows all the general stats in bar and pie charts, then there is also a page
                to make line graphs out of tags or categories used across the lifespan of the WordPress blog.
                It's been a while since I worked on these plugins but it looks like they still work.
                When creating the plugins I tried to make them work on any version of PHP and any version of WordPress.
            </p>

            <h3 className="sub-head">Website: RSVP Reader (2019)</h3>

            <p>
                Link: <a
                href={'https://rsvpreader.com/'} rel="noreferrer noopener" target="_blank">https://rsvpreader.com/</a>
            </p>

            <p>
                <font style={{fontWeight: 'bold'}}>PHP (Laravel)</font>, <font
                style={{fontWeight: 'bold'}}>VueJS</font>, <font
                style={{fontWeight: 'bold'}}>MySQL</font>, <font
                style={{fontWeight: 'bold'}}>CSS</font>, <font
                style={{fontWeight: 'bold'}}>AWS S3</font>, <font
                style={{fontWeight: 'bold'}}>Nginx</font>
            </p>

            <p>
                While it's a very simple web-based app, but like a lot of my projects it uses a few different
                technologies.
                RSVP is a way of reading where you don't have to move your eyes, you simply look at a fixed point and
                the words flash in front of you.
                The website allows you to choose one of the books available and press play to begin reading... and
                that's all there is to it!
                It was originally a Laravel project with a plain JavaScript front end.
                At some point, I made the Laravel an API and converted the JavaScript into Vue and hosted it as a static
                website on AWS S3.
                So, while it's very simple it mimics a more complex website or app that would also use an API.
            </p>


        </div>
    );
}