import React from "react";


export default function Bio() {
    return (
        <div className="p-2 sm:p-0" style={{maxWidth: 800}}>
            {/*<h2 className="main-head">Neil Ludlow</h2>*/}


            <div style={{width: 208, margin: '0 auto', display: "block", border: '1px solid black'}}>
                <img src="https://content.exp-sd.com/shortdark-white.jpg" alt="Neil Ludlow" className="w-52"/>
            </div>


            <h3 className="sub-head">Summary</h3>

            <p>
                I am a web developer called <font className={'font-bold'}>Neil Ludlow</font> from Sheffield, UK,
                and <abbr title="Neil Ludlow">nlud.uk</abbr> has now become a portfolio/resume type website.
            </p>

            <h3 className="sub-head">Current Stack and Tools</h3>

            <div className="inline-block align-top">
                <ul className="list-disc">
                    <li><font className={'font-bold'}>JavaScript</font>:
                        <ul className="list-disc pl-5">
                            <li>Plain JavaScript</li>
                            <li>React Native</li>
                            <li>NodeJS</li>
                            <li>ReactJS</li>
                            <li>NestJS</li>
                            <li>TypeScript</li>
                            <li>VueJS</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>PHP</font>:
                        <ul className="list-disc pl-5">
                            <li>No Framework PHP</li>
                            <li>Laravel</li>
                            <li>WordPress</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>SQL Databases</font>:
                        <ul className="list-disc pl-5">
                            <li>MySQL</li>
                            <li>MariaDB</li>
                            <li>PostgreSQL</li>
                            <li>Sqlite</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>NoSQL Databases</font>:
                        <ul className="list-disc pl-5">
                            <li>MongoDB</li>
                            <li>Firebase Firestore</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>Linux</font>:
                        <ul className="list-disc pl-5">
                            <li>Ubuntu</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>Web Servers</font>:
                        <ul className="list-disc pl-5">
                            <li>Nginx</li>
                            <li>Apache</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>CloudFlare</font></li>
                </ul>
            </div>

            <div className="inline-block align-top p-5 w-52">

            </div>

            <div className="inline-block align-top">
                <ul className="list-disc">
                    <li><font className={'font-bold'}>AWS</font>:
                        <ul className="list-disc pl-5">
                            <li>S3</li>
                            <li>EC2</li>
                            <li>RDS</li>
                            <li>Elastic Beanstalk</li>
                            <li>CloudFront (inc. Functions)</li>
                            <li>WAF</li>
                            <li>Lambda@Edge</li>
                            <li>IAM</li>
                            <li>CodePipeline</li>
                            <li>CodeDeploy</li>
                            <li>Lightsail</li>
                            <li>Amazon Certificate Manager</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>Source Control</font>:
                        <ul className="list-disc pl-5">
                            <li>Git</li>
                            <li>SVN</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>CSS</font>:
                        <ul className="list-disc pl-5">
                            <li>plain CSS 3</li>
                            <li>Bootstrap</li>
                            <li>Tailwind</li>
                            <li>Bulma</li>
                        </ul>
                    </li>
                    <li><font className={'font-bold'}>HTML 5</font></li>
                    <li><font className={'font-bold'}>Flutter/Dart</font></li>
                    <li><font className={'font-bold'}>Python</font></li>
                    <li><font className={'font-bold'}>Rust</font></li>
                </ul>
            </div>


            <h3 className="sub-head">My Software Profiles</h3>

            <p>
                Shortdark has been my business name for many years, my profiles are either in my name or Shortdark.
            </p>

            <ul className="list-disc pb-3">
                <li><a href={'https://github.com/shortdark'} rel="noreferrer noopener" target="_blank">Shortdark @
                    GitHub</a> - public code repository
                </li>
                <li><a href={'https://www.npmjs.com/~shortdark'} rel="noreferrer noopener" target="_blank">Shortdark @
                    NPM</a> - javascript packages
                </li>
                <li><a href={'https://packagist.org/users/shortdark/'} rel="noreferrer noopener" target="_blank">Shortdark
                    @ Packagist</a> - PHP packages
                </li>
                <li><a href={'https://profiles.wordpress.org/shortdark/'} rel="noreferrer noopener" target="_blank">Shortdark
                    @ WordPress</a> - WordPress plugins
                </li>
                <li><a href={'https://apps.apple.com/gb/developer/neil-ludlow/id1631513791'} rel="noreferrer noopener"
                       target="_blank">Neil Ludlow @ App
                    Store</a> - iOS apps
                </li>
                <li><a href={'https://play.google.com/store/apps/developer?id=Shortdark'} rel="noreferrer noopener"
                       target="_blank">Shortdark @ Google Play</a> -
                    android apps
                </li>
                <li><a href={'https://www.linkedin.com/in/neil-ludlow-48a6b015b'} rel="noreferrer noopener"
                       target="_blank">Neil Ludlow @ LinkedIn</a></li>
                <li><a href={'https://shortdark.co.uk/author/neil-ludlow/'} rel="noreferrer noopener" target="_blank">Neil
                    Ludlow @ Shortdark</a> - articles I've
                    written regarding software and communication
                </li>
            </ul>

            <p>
                See also, my <a href={'/projects'}>Personal Projects</a> for discussion on individual software.
            </p>
        </div>
    );
}
