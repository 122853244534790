import React from "react";

import {NavLink} from "react-router-dom"

function Nav2() {

    return (
        <div className="Nav">
            <nav>
                <ul className="flex">
                    <li><NavLink to="/info/linux">Linux</NavLink></li>
                    <li><NavLink to="/info/nano">Nano</NavLink></li>
                    <li><NavLink to="/info/vim">Vim</NavLink></li>
                    <li><NavLink to="/info/locations">Linux Locations</NavLink></li>
                    <li><NavLink to="/info/bash">BASH</NavLink></li>
                    <li><NavLink to="/info/mysql">MySQL</NavLink></li>
                    <li><NavLink to="/info/postgres">PostgreSQL</NavLink></li>
                    <li><NavLink to="/info/git">GIT</NavLink></li>
                </ul>
            </nav>
        </div>
    );
}

export default Nav2;
