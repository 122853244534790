import React from "react";

import {NavLink} from "react-router-dom";

// To re-add the info section just add it to the navigation.

function Nav() {

    return (
        <>
            <div className="Nav">
                <nav>
                    <ul className="flex">
                        <li><NavLink to="/">Bio</NavLink></li>
                        <li><NavLink to="projects">Personal Projects</NavLink></li>
                        <li><NavLink to="about">About This Website</NavLink></li>
                    </ul>
                </nav>
            </div>
        </>

    );
}

export default Nav;
