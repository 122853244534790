import React from "react";
import Nav2 from "../../components/Nav2";


export default function Postgres() {
    return (
        <>
            <Nav2/>
            <div className="p-2 sm:p-0">
                <h2 className="main-head">PostgreSQL</h2>

                <h3 className="sub-head">Postgres Commands</h3>

                <p>
                    <code>\du</code><br/>
                    List roles
                </p>
                <p>
                    <code>\l</code><br/>
                    List databases
                </p>
                <p>
                    <code>\c dbname</code><br/>
                    Change to database
                </p>
                <p>
                    <code>\dt</code><br/>
                    List tables
                </p>
                <p>
                    <code>\d+ tablename</code><br/>
                    Describe table
                </p>
                <p>
                    <code>\q</code><br/>
                    Quit
                </p>


                <h3 className="sub-head">Connecting to Postgres</h3>
                <p>
                    <code>sudo -u myuser psql</code><br/>
                    Connect to postgres using sudo
                </p>
                <p>
                    <code>psql -h localhost -p 5432 -U myuser dbname</code><br/>
                    Connect to a specific database
                </p>
                <p>
                    <code>psql --host=remotehost.rds.amazonaws.com --port=5432 --username=myuser --password</code><br/>
                    Alternative format to connect to postgres on a remote host explicitly requesting to use a password
                </p>


                <h3 className="sub-head">Postgres Dump/Restore</h3>
                <p>
                    <code>pg_dump -h remotehost.rds.amazonaws.com -p 5432 -U myuser dbname &gt; outfile.sql</code><br/>
                    Copy database to a file
                </p>
                <p>
                    <code>psql dbname &lt; infile.sql</code><br/>
                    Copy contents of a file to the database
                </p>


                <h3 className="sub-head">Postgres Create a Database / User</h3>
                <p>
                    <code>CREATE DATABASE dbname;</code><br/>
                    Create a database
                </p>
                <p>
                    <code>CREATE USER myuser WITH ENCRYPTED PASSWORD 'mypass';</code><br/>
                    Create a user
                </p>
                <p>
                    <code>GRANT ALL PRIVILEGES ON DATABASE dbname TO myuser;</code><br/>
                    Grant privileges to the user
                </p>


                <h3 className="sub-head">Postgres Enums</h3>

                <p>
                    <code>INSERT INTO users (something) VALUES ('enum_value') LIMIT 1;</code><br/>
                    This will fail if the column "something" is an enum.
                </p>

                <p>You might get an error like: <br/>
                    > ERROR: column "something" is of type pre_existing_enum but expression is of type text</p>

                <p>
                    <code>SELECT unnest(enum_range(NULL::pre_existing_enum));</code><br/>
                    View info about the enum
                </p>

                <p>
                    <code>\dT+ pre_existing_enum</code><br/>
                    An alternative way to get information about an enum
                </p>

                <p>
                    <code>INSERT INTO tablename (something) VALUES ('enum_value'::pre_existing_enum) LIMIT
                        1;</code><br/>
                    Having been told that the column is an enum, this is the way to insert data into an enum
                </p>

            </div>
        </>
    );
}
