import React from "react";

import Nav2 from "../components/Nav2";

export default function Info() {
    return (
        <>
            <Nav2/>
            <div className="p-2 sm:p-0">
                <h2 className="main-head">Info</h2>

                <p>
                    This website used to be a place where I'd store some notes about various code-related things,
                    especially if I thought I may forget them.
                </p>


            </div>
        </>

    );
}